/* body, html {
    margin: 0;
    padding: 0;
    margin-bottom: 50px;
} */

/* html, body {
  background-color: white; 
  margin: 0;
  padding: 0;
  width: 100%; 
} */

#root {
-webkit-overflow-scrolling: touch; 
background-color: white; 
}

/* Hide scrollbar for IE, Edge, and Firefox */
html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}


body {
  margin: 0;
  padding: 0;
  background-color: white;
  width: 100%;
  /* No need to hide overflow here if we're hiding the scrollbar effectively */
}


body, html {
  font-family: 'Roboto', sans-serif !important;
}

#root {
  -webkit-overflow-scrolling: touch; /* Keep for smooth scrolling on touch devices */
  background-color: white;
}